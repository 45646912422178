import './landing.scss';
import * as React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import FiveWordPanel from '../../art/5wordpanel';

import tuTheThoPortrait from '../../../images/Tu_The_Tho_1978.PNG';
import paths from '../../shared/url';
import Footer from '../../shared/footer';
import { PageSection } from '../../shared/page';

const goldColor = '#d7c57a';
const semiDarkGold = '#d5b122';
const darkGold = '#d9ba3c';

class LandingPage extends React.Component {
  render() {
    return (
      <div className="LandingPage">
        <div className={'contents-container'}>
          <div className={'section nhat-ton-nhu-lai-tang'}>
            <div className={'contents'}>
              <div className={'title'}>
                <h3>PHÁP TẠNG PHẬT GIÁO VIỆT NAM</h3>
                <br></br>
                <h1>NHẤT TÔN NHƯ LAI TẠNG</h1>
              </div>
              <div className={'heading'}>
                <p>Lời Giới Thiệu</p>
              </div>
              <div className={'text'}>
                <p>
                  Nhân sinh vốn đều <em>sẵn</em> hạt giống
                  Như Lai, sẵn toàn chân toàn thiện toàn mỹ.
                </p>
                <p>
                  Bởi trót lầm Tạng thức mà bị hóa ra tuồng Căn tánh Chủng
                  tánh Chúng sanh tánh khiến Tạng cách biệt. Nay các bậc hành
                  giả tín tâm y tôn chỉ Như Lai Tạng nương pháp độ Giáo Thiền,
                  lấy tự tánh làm cơ sở thực hành Tín Hạnh Nguyện...đó chính
                  thực tu trên con đường khai thông tạng thức. Tạng giác tức
                  Như Lai đồng hiệp Tạng tức <em>Như Lai Tạng</em>.
                </p>
                <div className={'big-word-panel-container'}>
                  <FiveWordPanel text={'NHẤT NGÔN TRI KIẾN PHẬT'} className={'left'} />
                  <FiveWordPanel text={'HOÀN TẤT GIẢI THOÁT MÔN'} className={'right'} />
                </div>
                <p>
                  Tín Hạnh Nguyện mỗi hành giả biểu hiện trên ý chí sử dụng
                  năng khiếu sở trường mình vào sự thực hành xây dựng công
                  đức, phẩm hạnh, tiến tu Đạo đức - Tọa thiền - Giáo lý.
                </p>
                <p className={'click-more'}>
                  <a href={paths.phapMon}>Tìm hiểu Pháp Môn Như Lai Tạng.</a>
                </p>
              </div>
            </div>
          </div>
          <div style={{
            backgroundColor: goldColor,
          }} className={'section duc-vo-thuong-ton'}>
            <img className={'separator separator-1-2'}
                 style={{
                 }}
                 src={'data:image/svg+xml;base64,' + btoa(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2900 296"><defs><style>.a{fill:#f8f7f8;}</style></defs><title>back-01</title><path class="a" d="M2900,.13C2681.19,0,2605.8,40.35,2496.26,243.65l-370.07,39.06c-149.55-176-301.28-239.5-546.43-213.65L881.2,142.75C548.14,177.92,340.35,152.48,209.3,0Z"/></svg>`)} /> <div className={'contents'}>
              <div className={'heading'}>
                <p>Đức Di Lạc Vô Thượng Tôn Phật</p>
              </div>
              <div className={'portrait-container'}>
                <img src={tuTheThoPortrait} />
              </div>
              <div className={'text'}>
                <p>2.500 năm sau đức Phật Thích Ca, thời nhân tâm chẳng còn nhân tính,
                  khoa học phát triển đến cực kỳ, Đức Di Lạc Hiện Thân Tăng
                  chủ Tịnh Vương chỉnh trang chánh pháp,
                  Ngài thành lập hạnh Nhân Thiên thừa lần đưa xuất thế pháp môn,
                  dụng Sắc Thanh Hương
                  Vị Xúc Pháp không nhiễm, tự tại vô ngại đến Đại bi Tri kiến Giải thoát.
                </p>
                <p>
                  Từ giáo lý Vô thượng Thích Ca Mâu Ni Phật, Đức Ngài cải cách
                  hoàn toàn đường lối Tu Phật, chẳng y kinh chẳng ly kinh, thực
                  hiện con đường Đồng hoá Nhân sinh trên thực tiễn, đạo đời hiệp
                  nhất trường tồn phật tôn, chẳng xuất thế mà rốt ráo xuất thế.
                </p>
                <p>
                  Mỗi bậc tu tự mình thật hành trọn từng mỗi pháp Độ, lấy đó làm
                  phương thức tiến tu căn bản, tự tánh mình nhiếp độ chúng sanh tánh nơi
                  thân mạng mình, cho đến tận độ chúng sanh tánh giai thành Phật Tánh.
                </p>
              </div>
              <p className={'click-more'}>
                <a href={paths.luocSu}>Đọc về lịch sử Đức Di Lạc.</a>
              </p>
            </div>
            <img
              className={'separator'}
              src={'data:image/svg+xml;base64,' + btoa(`<svg width="1440" height="431" viewBox="0 0 1440 431" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M-693.144 430.619C-523.91 133.46 -131.824 112.575 -5.83173 130.161C338.135 178.19 444.451 488.92 932.589 416.128C1351.52 353.649 1836.24 278.531 1812.09 282.235C2014.56 251.604 2217.01 192.829 2385.13 77.3206C2446.11 35.4158 2512.51 -28.3741 2657.68 -28.3741C2899.4 -28.3741 3009.4 430.619 3009.4 430.619H-693.144Z" fill="${semiDarkGold}" />
</svg>`)}
              style={{
                zIndex: 3,
                bottom: 0,
                width: '100vw',
              }}
            />
            <img
              className={'separator'}
              src={'data:image/svg+xml;base64,' + btoa(`<svg width="1440" height="322" viewBox="0 0 1440 322" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M-929.396 321.852C-713.153 322 -638.647 276.042 -530.391 44.4884L-164.661 -1.4192e-05C-16.8651 200.46 133.085 272.785 375.36 243.342L1065.73 159.411C1394.88 119.353 1600.24 148.329 1729.75 322L-929.396 321.852Z" fill="${darkGold}" />
</svg>`)}
              style={{
                bottom: 0,
                width: '100vw',
              }}
            />
          </div>
          <div className={'section hanh-trinh-hoa-do'}>
            <img
              className={'separator'}
              src={'data:image/svg+xml;base64,' + btoa(`<svg width="1440" height="185" viewBox="0 0 1440 185" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2041 -0.914797C1873.95 -1 1816.39 25.4456 1732.77 158.69L1450.24 184.29C1336.06 68.9383 1220.22 27.3201 1033.07 44.2623L499.751 92.5591C245.477 115.61 86.8397 98.9362 -13.2102 -1L2041 -0.914797Z" fill="${darkGold}" />
</svg>`)}
              style={{
                top: -5,
                width: '100vw',
              }}
            />
          </div>
          <div className={'section giao-thien'}>
            <div className={'contents'} style={{position: 'relative'}}>
              <div className={'heading'}>
                <p>Giáo thiền</p>
              </div>
              <div className={'quote-with-marks'}>
                <p>
                  Đạo tràng là nơi chứng tri, tri kiến, tu chứng từng thứ bậc
                  của chư Bồ Tát đại căn, đại chí, đại nguyện, đầy đủ phẩm hạnh,
                  đầy đủ công năng bất khả thuyết mà thành Phật.
                </p>
              </div>
              <div className={'text'}>
                <p>
                  Như Lai Tạng trùm khắp không biên giới, nên đạo tràng Như Lai Tạng
                  vốn không biên giới.
                </p>
                <p>
                  Hành đại Bồ Tát hành thâm Tướng Pháp thu đạt Giác Tướng, thực hiện
                  trang nghiêm Quốc độ Phật cúng dường Như Lai làm đạo tràng của Bồ
                  Tát vì hóa độ Chúng sanh. Ngoài ra, hết thảy các từng lớp tu chứng
                  từ giới Thế gian đến giới Xuất thế gian đều phải nương đạo tràng để
                  tu tập, tu hành, tu trì nghiêm túc hạnh trang nghiêm Phật quốc, thứ
                  lớp thoát giới, trở về rốt ráo "không Giới giải thoát".
                </p>
                <p className={'click-more'}>
                  <a href={paths.veChungToi}>Về chúng tôi.</a> <br/>
                  <Link to={paths.veChungToi + '#anh-hoat-dong'}>Ảnh hoạt động.</Link>
                </p>
              </div>
            </div>
          </div>
          <PageSection sectionName={'footer'}>
            <Footer />
          </PageSection>
        </div>
      </div>
    );
  }
}

export default LandingPage;
