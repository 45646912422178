function newAlbumPage(urlPath, title, images) {
  return { urlPath, title, images };
}

export default function getAlbumPages() {
  return [
    newAlbumPage('2024-dai-le-trung-thu', '2024 Tri Ân Tam Thế Phật - Đại Lễ Trung Thu (ngày 15 tháng 8, năm Giáp Thìn)', [
      '/images/hoatdong/2024_triantamthephat_dailetrungthu/_NIK5675.JPG',
      '/images/hoatdong/2024_triantamthephat_dailetrungthu/_NIK5677.JPG',
      '/images/hoatdong/2024_triantamthephat_dailetrungthu/IMG_9094.JPG',
      '/images/hoatdong/2024_triantamthephat_dailetrungthu/_NIK5687.JPG',
      '/images/hoatdong/2024_triantamthephat_dailetrungthu/_NIK5693.JPG',
      '/images/hoatdong/2024_triantamthephat_dailetrungthu/_NIK5694.JPG',
      '/images/hoatdong/2024_triantamthephat_dailetrungthu/_NIK5698.JPG',
      '/images/hoatdong/2024_triantamthephat_dailetrungthu/_NIK5702.JPG',
      '/images/hoatdong/2024_triantamthephat_dailetrungthu/_NIK5704.JPG',
      '/images/hoatdong/2024_triantamthephat_dailetrungthu/IMG_9105.JPG',
    ]),
    newAlbumPage('2024-dai-le-vu-lan', '2024 Đại Lễ Vu Lan (ngày 15 tháng 7, năm Giáp Thìn)', [
      '/images/hoatdong/2024_dailevulan/img6.JPG',
      '/images/hoatdong/2024_dailevulan/img1.JPG',
      '/images/hoatdong/2024_dailevulan/img2.JPG',
      '/images/hoatdong/2024_dailevulan/img3.JPG',
      '/images/hoatdong/2024_dailevulan/img4.JPG',
      '/images/hoatdong/2024_dailevulan/img5.JPG',
    ]),
    newAlbumPage('2024-dai-le-tinh-vuong-ty-lo-gia-na', '2024 Đại Lễ Tịnh Vương Tỳ Lô Giá Na (ngày 10 tháng 3, năm Giáp Thìn)', [
      '/images/hoatdong/2024_dailetinhvuongtylogiana/_NIK5512.JPG',
      '/images/hoatdong/2024_dailetinhvuongtylogiana/_NIK5483.JPG',
      '/images/hoatdong/2024_dailetinhvuongtylogiana/_NIK5486.JPG',
      '/images/hoatdong/2024_dailetinhvuongtylogiana/_NIK5490.JPG',
      '/images/hoatdong/2024_dailetinhvuongtylogiana/_NIK5488.JPG',
      '/images/hoatdong/2024_dailetinhvuongtylogiana/_NIK5495.JPG',
      '/images/hoatdong/2024_dailetinhvuongtylogiana/_NIK5493.JPG',
      '/images/hoatdong/2024_dailetinhvuongtylogiana/_NIK5455.JPG',
      '/images/hoatdong/2024_dailetinhvuongtylogiana/_NIK5457.JPG',
      '/images/hoatdong/2024_dailetinhvuongtylogiana/_NIK5447.JPG',
      '/images/hoatdong/2024_dailetinhvuongtylogiana/_NIK5448.JPG',
    ]),
    newAlbumPage('2024-dai-le-khai-nguyen', '2024 Đại Lễ Khai Nguyên (ngày 12 tháng 1, năm Giáp Thìn)', [
      '/images/hoatdong/2024_dailekhainguyen/IMG_4176.JPG',
      '/images/hoatdong/2024_dailekhainguyen/_BC17685.JPG',
      '/images/hoatdong/2024_dailekhainguyen/_BC17692.JPG',
      '/images/hoatdong/2024_dailekhainguyen/_BC17704.JPG',
      '/images/hoatdong/2024_dailekhainguyen/_BC17717.JPG',
      '/images/hoatdong/2024_dailekhainguyen/_BC17711.JPG',
      '/images/hoatdong/2024_dailekhainguyen/_BC17724.JPG',
      '/images/hoatdong/2024_dailekhainguyen/_BC17678.JPG',
      '/images/hoatdong/2024_dailekhainguyen/IMG_4169.JPG',
      '/images/hoatdong/2024_dailekhainguyen/_BC17943.JPG',
      '/images/hoatdong/2024_dailekhainguyen/_BC17958.JPG',
      '/images/hoatdong/2024_dailekhainguyen/_BC17942.JPG',
      '/images/hoatdong/2024_dailekhainguyen/_BC17960.JPG',

    ]),
    newAlbumPage('2024-vui-xuan-giap-thin', '2024 Các Hoạt Động Vui Đón Xuân Giáp Thìn', [
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3949.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3850.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3557.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3556.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3975.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3976.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3977.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3553.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3559.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3554.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3990.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3972.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3973.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3971.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3962.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3560.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3561.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3563.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3980.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3981.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3941.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3942.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3944.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3943.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3945.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3947.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3951.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3950.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3952.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3982.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3983.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3984.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3985.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3986.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3989.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3993.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3994.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3995.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_3991.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_4002.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_4008.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_4009.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_4010.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_4011.JPG',
      '/images/hoatdong/2024_vuixuangiapthin/IMG_4012.JPG',
    ]),
    newAlbumPage('2024-ton-giao-phuong-22', '2024 Đoàn Thể Chính Trị, Tôn Giáo Phường 22, Quận Bình Thạnh Thăm Đạo Tràng Di Lạc (ngày 5 tháng 2 năm 2024)', [
      '/images/hoatdong/2024_tongiaophuong22/IMG_3617.JPG',
      '/images/hoatdong/2024_tongiaophuong22/IMG_3620.JPG',
      '/images/hoatdong/2024_tongiaophuong22/IMG_3631.JPG',
      '/images/hoatdong/2024_tongiaophuong22/IMG_3632.JPG',
      '/images/hoatdong/2024_tongiaophuong22/IMG_3639.JPG',
      '/images/hoatdong/2024_tongiaophuong22/IMG_3642.JPG',
      '/images/hoatdong/2024_tongiaophuong22/IMG_3644.JPG',
    ]),
    newAlbumPage('2024-vieng-thap-thay-tai-nha-trang', '2024 Tứ Chúng Viếng Bảo Tháp Đức Ngài Tại Nha Trang (ngày 20 tháng 1 năm 2024, nhằm ngày 10 tháng 12 năm Quý Mão)', [
      '/images/hoatdong/2024_viengthapthaytainhatrang/Photo 2024-02-16 21.02.55.jpg',
      '/images/hoatdong/2024_viengthapthaytainhatrang/_BC17370.JPG',
      '/images/hoatdong/2024_viengthapthaytainhatrang/_BC17373.JPG',
      '/images/hoatdong/2024_viengthapthaytainhatrang/_BC17387.JPG',
      '/images/hoatdong/2024_viengthapthaytainhatrang/_BC17390.JPG',
      '/images/hoatdong/2024_viengthapthaytainhatrang/_BC17391.JPG',
      '/images/hoatdong/2024_viengthapthaytainhatrang/_BC17402.JPG',
      '/images/hoatdong/2024_viengthapthaytainhatrang/IMG_0587.JPG',
      '/images/hoatdong/2024_viengthapthaytainhatrang/IMG_2990.JPG',
      '/images/hoatdong/2024_viengthapthaytainhatrang/IMG_3010.JPG',
      '/images/hoatdong/2024_viengthapthaytainhatrang/IMG_3022.JPG',
      '/images/hoatdong/2024_viengthapthaytainhatrang/IMG_3023.JPG',
      '/images/hoatdong/2024_viengthapthaytainhatrang/IMG_3029.JPG',
      '/images/hoatdong/2024_viengthapthaytainhatrang/IMG_3032.JPG',
      '/images/hoatdong/2024_viengthapthaytainhatrang/IMG_3139.JPG',
      '/images/hoatdong/2024_viengthapthaytainhatrang/IMG_3140.JPG',
      '/images/hoatdong/2024_viengthapthaytainhatrang/IMG_3141.JPG',
      '/images/hoatdong/2024_viengthapthaytainhatrang/IMG_3142.JPG',
      '/images/hoatdong/2024_viengthapthaytainhatrang/IMG_3963.JPG',
      '/images/hoatdong/2024_viengthapthaytainhatrang/IMG_3964.JPG',
      '/images/hoatdong/2024_viengthapthaytainhatrang/IMG_3965.JPG',
      '/images/hoatdong/2024_viengthapthaytainhatrang/IMG_3966.JPG',
      '/images/hoatdong/2024_viengthapthaytainhatrang/IMG_3967.JPG',
      '/images/hoatdong/2024_viengthapthaytainhatrang/IMG_3969.JPG',
    ]),
    newAlbumPage('2024-dai-le-dan-sanh', '2024 Đại Lễ Đản Sanh (ngày 24 tháng 12 năm Quý Mão)', [
      '/images/hoatdong/2024_dailedansanh/IMG_3528.JPG',
      '/images/hoatdong/2024_dailedansanh/IMG_3412.JPG',
      '/images/hoatdong/2024_dailedansanh/IMG_3413.JPG',
      '/images/hoatdong/2024_dailedansanh/_BC17446.JPG',
      '/images/hoatdong/2024_dailedansanh/IMG_3423.JPG',
      '/images/hoatdong/2024_dailedansanh/_BC17461.JPG',
      '/images/hoatdong/2024_dailedansanh/_BC17455.JPG',
      '/images/hoatdong/2024_dailedansanh/IMG_3454.JPG',
      '/images/hoatdong/2024_dailedansanh/_BC17474.JPG',
      '/images/hoatdong/2024_dailedansanh/_BC17476.JPG',
      '/images/hoatdong/2024_dailedansanh/_BC17477.JPG',
      '/images/hoatdong/2024_dailedansanh/IMG_3470.JPG',
      '/images/hoatdong/2024_dailedansanh/IMG_3472.JPG',
      '/images/hoatdong/2024_dailedansanh/IMG_3449.JPG',
      '/images/hoatdong/2024_dailedansanh/_BC17484.JPG',
      '/images/hoatdong/2024_dailedansanh/IMG_3459.JPG',
      '/images/hoatdong/2024_dailedansanh/_BC17491.JPG',
      '/images/hoatdong/2024_dailedansanh/_BC17494.JPG',
      '/images/hoatdong/2024_dailedansanh/IMG_3550.JPG', //hình bánh
      '/images/hoatdong/2024_dailedansanh/_BC17531.JPG',
      '/images/hoatdong/2024_dailedansanh/_BC17551.JPG',
      '/images/hoatdong/2024_dailedansanh/_BC17554.JPG',
      '/images/hoatdong/2024_dailedansanh/_BC17535.JPG',
      '/images/hoatdong/2024_dailedansanh/_BC17542.JPG',
      '/images/hoatdong/2024_dailedansanh/_BC17560.JPG',
      '/images/hoatdong/2024_dailedansanh/_BC17563.JPG',
      '/images/hoatdong/2024_dailedansanh/IMG_3535.JPG',
      '/images/hoatdong/2024_dailedansanh/IMG_3540.JPG',
      '/images/hoatdong/2024_dailedansanh/IMG_3526.JPG',
      '/images/hoatdong/2024_dailedansanh/_BC17568.JPG',
      '/images/hoatdong/2024_dailedansanh/IMG_3549.JPG',
      '/images/hoatdong/2024_dailedansanh/_BC17574.JPG',
      '/images/hoatdong/2024_dailedansanh/_BC17576.JPG',
      '/images/hoatdong/2024_dailedansanh/_BC17581.JPG',
      '/images/hoatdong/2024_dailedansanh/_BC17588.JPG',

    ]),
    newAlbumPage('2024-le-dan-sanh-130ntt', '2024 Đại Lễ Đản Sanh Tại Đạo Tràng Di Lạc (ngày 23 tháng 12 năm Quý Mão)', [
      '/images/hoatdong/2024_ledansanh130ntt/_BC17416.JPG',
      '/images/hoatdong/2024_ledansanh130ntt/_BC17418.JPG',
      '/images/hoatdong/2024_ledansanh130ntt/_BC17424.JPG',
      '/images/hoatdong/2024_ledansanh130ntt/_BC17429.JPG',
      '/images/hoatdong/2024_ledansanh130ntt/_BC17432.JPG',
      '/images/hoatdong/2024_ledansanh130ntt/IMG_3394.JPG',
      '/images/hoatdong/2024_ledansanh130ntt/IMG_3395.JPG',
      '/images/hoatdong/2024_ledansanh130ntt/IMG_3397.JPG',
      '/images/hoatdong/2024_ledansanh130ntt/IMG_3399.JPG',
      '/images/hoatdong/2024_ledansanh130ntt/IMG_3401.JPG',
      '/images/hoatdong/2024_ledansanh130ntt/IMG_3402.JPG',
      '/images/hoatdong/2024_ledansanh130ntt/IMG_3403.JPG',
      '/images/hoatdong/2024_ledansanh130ntt/IMG_3404.JPG',
      '/images/hoatdong/2024_ledansanh130ntt/IMG_3407.JPG',
    ]),
    newAlbumPage('2023-le-nhan-giay-phep-130-ntt', '2023 Lễ Nhận Giấy Phép Đạo Tràng Di Lạc (Hồ Chí Minh, ngày 1 tháng 12 năm 2023)', [
      '/images/hoatdong/2023_lenhangiayphep130ntt/IMG_1337.JPG',
      '/images/hoatdong/2023_lenhangiayphep130ntt/IMG_1338.JPG',
      '/images/hoatdong/2023_lenhangiayphep130ntt/IMG_1363.JPG',
      '/images/hoatdong/2023_lenhangiayphep130ntt/IMG_1255.JPG',
      '/images/hoatdong/2023_lenhangiayphep130ntt/IMG_1343(1).JPG',
    ]),
    newAlbumPage('2023-le-nhan-giay-phep-quang-ngai', '2023 Lễ Nhận Giấy Phép Tại Đạo Tràng Như Lai Thiền (Quảng Ngãi, ngày 14 tháng 6 năm 2023)', [
      '/images/hoatdong/2023_lenhangiayphepquangngai/0U5A4849.JPG',
      '/images/hoatdong/2023_lenhangiayphepquangngai/0U5A4854.JPG',
      '/images/hoatdong/2023_lenhangiayphepquangngai/0U5A4857.JPG',
      '/images/hoatdong/2023_lenhangiayphepquangngai/0U5A4883.JPG',
      '/images/hoatdong/2023_lenhangiayphepquangngai/0U5A4888.JPG',
      '/images/hoatdong/2023_lenhangiayphepquangngai/0U5A4898.JPG',
      '/images/hoatdong/2023_lenhangiayphepquangngai/UI5A1502.JPG',
      '/images/hoatdong/2023_lenhangiayphepquangngai/UI5A1508.JPG',
      '/images/hoatdong/2023_lenhangiayphepquangngai/UI5A1510.JPG',
      '/images/hoatdong/2023_lenhangiayphepquangngai/UI5A1551.JPG',
      '/images/hoatdong/2023_lenhangiayphepquangngai/UI5A1601.JPG',
      '/images/hoatdong/2023_lenhangiayphepquangngai/UI5A1614.JPG',
      '/images/hoatdong/2023_lenhangiayphepquangngai/UI5A1620.JPG',
      '/images/hoatdong/2023_lenhangiayphepquangngai/UI5A1631.JPG',
      '/images/hoatdong/2023_lenhangiayphepquangngai/UI5A1640.JPG',
      '/images/hoatdong/2023_lenhangiayphepquangngai/20231227_123457.jpeg',
      '/images/hoatdong/2023_lenhangiayphepquangngai/20231227_123628.jpeg',
    ]),
    newAlbumPage('2023-le-an-vi-tai-nha-thien', '2023 Lễ An Vị Tại Nhà Thiền, (ngày 5 tháng 2 năm 2023, nhằm ngày 15 tháng 1 năm Quý Mão)', [
      '/images/hoatdong/2023_leanvi/IMG_8086.jpg',
      '/images/hoatdong/2023_leanvi/IMG_8140.jpeg',
      '/images/hoatdong/2023_leanvi/IMG_8143.jpeg ',
      '/images/hoatdong/2023_leanvi/IMG_4185.JPG',
      '/images/hoatdong/2023_leanvi/IMG_4193.JPG',
      '/images/hoatdong/2023_leanvi/IMG_4195.JPG',
      '/images/hoatdong/2023_leanvi/IMG_4234 - Copy.JPG',
    ]),

    newAlbumPage('2023-dai-le-phat-dan', '2023 Đại Lễ Phật Đản (ngày 8 tháng 4, năm Quý Mão)', [
      '/images/hoatdong/2023_dailephatdan/IMG_7458.jpeg',
      '/images/hoatdong/2023_dailephatdan/IMG_7462.jpeg',
      '/images/hoatdong/2023_dailephatdan/IMG_7464.jpeg',
      '/images/hoatdong/2023_dailephatdan/IMG_7465.jpeg',
      '/images/hoatdong/2023_dailephatdan/IMG_7484.jpeg',
      '/images/hoatdong/2023_dailephatdan/IMG_7492.JPG',
      'https://www.youtube.com/embed/DdG2u_-6GBg?si=cQJZloir8e8QUYlg',
    ]),
    newAlbumPage('2023-dai-le-khai-nguyen', '2023 Đại Lễ Khai Nguyên (ngày 12 tháng 1, năm Quý Mão)', [
      '/images/hoatdong/2023_dailekhainguyen/IMG_3887.JPG',
      '/images/hoatdong/2023_dailekhainguyen/IMG_3893.JPG',
      '/images/hoatdong/2023_dailekhainguyen/IMG_3906.JPG',
      '/images/hoatdong/2023_dailekhainguyen/IMG_3909.JPG',
      '/images/hoatdong/2023_dailekhainguyen/IMG_3921.JPG',
    ]),
    newAlbumPage('2023-dai-le-dan-sanh', '2023 Đại Lễ Đản Sanh (ngày 24 tháng 12, năm Nhâm Dần)', [
      '/images/hoatdong/2023_dailedansanh/_BC15032.JPG',
      '/images/hoatdong/2023_dailedansanh/_BC14970.JPG',
      '/images/hoatdong/2023_dailedansanh/_BC15073.JPG',
      '/images/hoatdong/2023_dailedansanh/_BC15101.JPG',
      '/images/hoatdong/2023_dailedansanh/_BC15111.JPG',
      '/images/hoatdong/2023_dailedansanh/IMG_3745.JPG',
      '/images/hoatdong/2023_dailedansanh/IMG_3780.JPG ',
      '/images/hoatdong/2023_dailedansanh/_BC15129.JPG',
      '/images/hoatdong/2023_dailedansanh/_BC15133.JPG',
      '/images/hoatdong/2023_dailedansanh/IMG_3774.JPG',
      '/images/hoatdong/2023_dailedansanh/IMG_3653.JPG',
      '/images/hoatdong/2023_dailedansanh/_BC15146.JPG',
      '/images/hoatdong/2023_dailedansanh/IMG_3617.JPG',
      '/images/hoatdong/2023_dailedansanh/IMG_3642.JPG',
      '/images/hoatdong/2023_dailedansanh/_BC14952.JPG',
      '/images/hoatdong/2023_dailedansanh/_BC14956.JPG',
      '/images/hoatdong/2023_dailedansanh/_BC14961.JPG',
      '/images/hoatdong/2023_dailedansanh/_BC14967.JPG',
      '/images/hoatdong/2023_dailedansanh/_BC15087.JPG',
    ]),

    newAlbumPage('2022-dai-le-khai-nguyen', '2022 Đại Lễ Khai Nguyên (ngày 12 tháng 1, năm Nhâm Dần)', [
      '/images/hoatdong/2022_dailekhainguyen/IMG_3000.JPG',
      '/images/hoatdong/2022_dailekhainguyen/IMG_3006.JPG',
      '/images/hoatdong/2022_dailekhainguyen/IMG_3007.JPG',
      '/images/hoatdong/2022_dailekhainguyen/IMG_3011.JPG',
      '/images/hoatdong/2022_dailekhainguyen/IMG_3016.JPG',
      '/images/hoatdong/2022_dailekhainguyen/IMG_3022.JPG',
      '/images/hoatdong/2022_dailekhainguyen/IMG_3023.JPG',
      '/images/hoatdong/2022_dailekhainguyen/IMG_3036.JPG',
      '/images/hoatdong/2022_dailekhainguyen/IMG_3043.JPG ',
    ]),
    newAlbumPage('2022-dai-le-dan-sanh', '2022 Đại Lễ Đản Sanh (ngày 24 tháng 12, năm Tân Sửu)', [

      '/images/hoatdong/2022_dailedansanh/IMG_2496.JPG',
      '/images/hoatdong/2022_dailedansanh/IMG_2524.JPG',
      '/images/hoatdong/2022_dailedansanh/IMG_2527.JPG',
      '/images/hoatdong/2022_dailedansanh/IMG_2537.JPG',
      '/images/hoatdong/2022_dailedansanh/IMG_2546.JPG',
      '/images/hoatdong/2022_dailedansanh/_BC13322.JPG',
      '/images/hoatdong/2022_dailedansanh/_BC13296.JPG',
      '/images/hoatdong/2022_dailedansanh/IMG_2586.JPG',
      '/images/hoatdong/2022_dailedansanh/IMG_2644.JPG',
      '/images/hoatdong/2022_dailedansanh/_BC13354.JPG',
      '/images/hoatdong/2022_dailedansanh/IMG_2633.JPG',
      '/images/hoatdong/2022_dailedansanh/_BC13357.JPG',
      '/images/hoatdong/2022_dailedansanh/IMG_2604.JPG',
      '/images/hoatdong/2022_dailedansanh/IMG_2652.JPG',
      '/images/hoatdong/2022_dailedansanh/IMG_2667.JPG',
      '/images/hoatdong/2022_dailedansanh/_BC13464.JPG',
      '/images/hoatdong/2022_dailedansanh/_BC13504.JPG',
      '/images/hoatdong/2022_dailedansanh/_BC13509.JPG',
      '/images/hoatdong/2022_dailedansanh/IMG_2738.JPG',
      '/images/hoatdong/2022_dailedansanh/IMG_2779.JPG',
      '/images/hoatdong/2022_dailedansanh/BC13510.JPG',
      '/images/hoatdong/2022_dailedansanh/IMG_2382.JPG',
      '/images/hoatdong/2022_dailedansanh/IMG_2417.JPG',
      '/images/hoatdong/2022_dailedansanh/IMG_2433.JPG',
      '/images/hoatdong/2022_dailedansanh/IMG_2440.JPG',
      '/images/hoatdong/2022_dailedansanh/IMG_2466.JPG',
      '/images/hoatdong/2022_dailedansanh/IMG_2488.JPG',

    ]),
    newAlbumPage('2020-le-29-thang-9', '2020 Đại Lễ Vía Đức Đông Độ Dược Sư Lưu Ly Quang Như Lai (ngày 29 Tháng 9, năm Canh Tý)', [
      '/images/hoatdong/2020_le29thang9/IMG_1210.JPG',
      '/images/hoatdong/2020_le29thang9/IMG_1213.JPG',
      '/images/hoatdong/2020_le29thang9/IMG_1216.JPG',
      '/images/hoatdong/2020_le29thang9/IMG_1234.JPG',
      '/images/hoatdong/2020_le29thang9/IMG_1239.JPG',
      '/images/hoatdong/2020_le29thang9/IMG_1245.JPG',
      '/images/hoatdong/2020_le29thang9/IMG_1248.JPG',
      '/images/hoatdong/2020_le29thang9/IMG_1258.JPG',
      '/images/hoatdong/2020_le29thang9/IMG_1272.JPG',
    ]),
  ]
}

