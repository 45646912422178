import './index.scss';
import logo from '../../../images/logo.png';

function Footer() {
  return (
    <div className={'Footer NavBar'}>
      <div className={'logo'}>
        <img src={logo} />
        <div className={'site-name'}>
          <h3>Pháp Tạng Phật Giáo Việt Nam</h3>
          <h2>Nhất Tôn Như Lai Tạng</h2>
        </div>
      </div>
      <div className={'items'}>
        <a className={'email'} href={'mailto:info@nhattonnhulaitang.com'}>info@<span>nhattonnhulaitang.com</span></a>
      </div>
    </div>
  );
}

export default Footer;
